button.closeDanger {
    height: 100%;
    width: 20px;
    border-color: #ff636f;
    padding: 0;
    background-color: transparent;
    border: 1;
    -webkit-appearance: none;
}

button.closeInfo {
    height: 100%;
    width: 20px;
    border-color: #96caff;
    padding: 0;
    background-color: transparent;
    border: 1;
    -webkit-appearance: none;
}

.bgimg {
    background-image: url("../static/images/bg_flower.png");
    background-repeat: no-repeat;
}

#brand-logo {
    margin-left: 15px;
}

.collapsible .collapsible-body {
    padding-top: 0.5rem;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
}

.dropdown {
    position: relative;
    display: block;
}

.dropdown .dropdowncontent {
    position: absolute;
    display: none;
}

.dropdown:hover .dropdowncontent {
    display: inline-block;
    white-space:nowrap;
    z-index:2
}

.dropdowncontent .dropdown .dropdowncontent {
    position: absolute;
    display: none;
}

.dropdowncontent .dropdown:hover .dropdowncontent {
    display: inline-block;
    white-space:nowrap;
    z-index:2
}

.dropdown div {
    display: block;
    padding: 0 15px;
}

.edit-color {
    height: 50px;
    cursor: pointer;
    padding: 0 0.75rem;
}

.fade {
    opacity: 0;
    transition: opacity .15s linear;
}

.fade.show {
    opacity: 1;
}

.flex-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.flex-header {
    flex-grow: .5;
}

.invalid-feedback {
    text-transform: capitalize;
    width: 100%;
    margin-top: .25rem;
    font-size: 80%;
    color: #dc3545
}

.my-color-profile .mdc-image-list__item {
    width: calc(25% - 4.25px);
    margin: 2px
}

.mdc-image-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 auto;
    padding: 0
}

.nav-2 {
    height: 28px;
}

.nav-2 ul li {
    height: 100%;
    line-height: 190%;
}

a.disabled {
    color: black;
    pointer-events: none;
}

a.per_page {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.pages {
    margin-left: 0.2rem;
    margin-right: 0.2rem;
}

.floatRight,
span.per_page,
.exportBatch {
    float: right;
}

.inline-block {
    display: inline-block;
}

.exportBatchIcon {
    font-size: 36px;
}

.batchStatus {
    margin: 4rem 0 1.68rem 0;
    display: inline;
}

h3.done {
    color: darkgreen;
    background-color: lightgreen;
}

h3.stopped {
    color: darkred;
    background-color: pink;
}

h3.running {
    color: darkorange;
    background-color: yellow;
}

/* Alerts and form errors */
.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}

.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert-warning {
    color: #8a6d3b;
    background-color: #fcf8e3;
    border-color: #faebcc;
}

.alert-danger {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
}

.alert p {
    margin-bottom: 0;
}

.alert:empty {
    display: none;
}

.tooltip {
    position: relative;
    display: inline-block;
    cursor: pointer;
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: -20px;
    left: 200%;
    opacity: 0;
    transition: opacity 0.5s;
}

.tooltip .tooltiptext::after {
    content: "";
    position: absolute;
    top: 25px;
    right: 100%;
    border-width: 5px;
    border-style: solid;
    border-color: transparent black transparent transparent;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}

#color_editor {
    position: fixed
}

#color_preview {
    height: 10.6rem;
    border-bottom: 1px solid #001c00;
    border-right: 1px solid #001c00;
}

#original_color_preview {
    height: 10.6rem;
    border-bottom: 1px solid #001c00;
    border-right: 1px solid #001c00;
}

.cm_edit_range {
    border-left: 1px solid #001c00;
    border-bottom: 1px solid #001c00;
    border-right: 1px solid #001c00;
}

.cm_edit_form {
    width: 50%;
    float: right;
}

@media (max-width: 600px) {

    #edit_colors_details_season,
    #edit_colors_details_temp,
    #edit_colors_details_hex_color {
        border-top: 1px solid #001c00;
        border-right: 1px solid #001c00;
        border-left: 1px solid #001c00;
    }

    #edit_colors_lab_row {
        border-top: 1px solid #001c00;
    }
}

@media (max-width: 600px) {

    #edit_colors_details_season,
    #edit_colors_details_temp,
    #edit_colors_details_hex_color {
        border-top: 1px solid #001c00;
        border-right: 1px solid #001c00;
        border-left: 1px solid #001c00;
    }

    #edit_colors_lab_row {
        border-top: 1px solid #001c00;
    }
}

@media (min-width: 601px) {
    #edit_colors_details_row {
        border: 1px solid #001c00;
    }

    #edit_colors_details_season,
    #edit_colors_details_temp {
        border-right: 1px solid #001c00;
    }
}

@media only screen and (max-width: 600px) {
    .small_screen_padding {
        padding: 24px 0 24px 0
    }
}

@media only screen and (min-width: 601px) {
    .small_screen_padding {
        padding: 24px
    }
}

.color_display {
    height: 50px;
}
